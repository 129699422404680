import React, { ReactElement, useEffect, useState, useContext } from 'react'
import moment from 'moment'

import { CircularProgress, makeStyles, Typography } from '@material-ui/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router' // imported with Gatsby
import clsx from 'clsx'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { navigate } from 'gatsby'
import { getPaystub } from '../../../services/payment.service'
import { Paystub } from '../../../models/payment'
import PaymentTypes from '../payment-types.component'
import { colors } from '../../../styles/colors'
import { MessageContext } from '../../../contexts/message.store'

export type PaystubComponentProps = {
  date?: string
  userId?: number
} & RouteComponentProps

const useStyles = makeStyles((theme) => ({
  progressInvisible: {
    visibility: 'hidden',
  },
  totalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: '8px',
    backgroundColor: colors.backgroundBlue,
  },
  paymentsSection: {
    marginTop: theme.spacing(3),
  },
  paystubWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  detailWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(3),
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  componentWrapper: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
    },
  },
  backArrow: {
    marginTop: '5px',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  amountText: {
    color: theme.palette.primary.main,
  },
}))

export default function PaystubDetailComponent({ date, userId }: PaystubComponentProps): ReactElement {
  const classes = useStyles()
  const [, messageDispatch] = useContext(MessageContext)
  const [isLoading, setLoading] = useState(false)
  const [paystub, setPaystub] = useState<Paystub>()
  const momentDate = moment(date, 'M-D-YYYY')

  const goBack = (): void => {
    const [month, year] = [momentDate.month(), momentDate.year()]
    navigate(`/?month=${month}&year=${year}`)
  }

  const getPaystubFromApi = async (): Promise<void> => {
    setLoading(true)
    try {
      setPaystub(await getPaystub(date, userId))
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: `${err.status}` })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (date && userId) getPaystubFromApi()
    else setPaystub(null)
  }, [date, userId])

  const detail = (): ReactElement => {
    const formattedDate = momentDate.format('MMMM Do, YYYY')
    const paymentTypes = (paymentType): ReactElement => {
      return (
        <PaymentTypes
          type={paymentType}
          data={paystub.payment_types[paymentType]}
          key={paymentType}
        />
      )
    }
    return (
      <div className={classes.componentWrapper}>
        <ArrowBackIcon className={classes.backArrow} onClick={goBack} fontSize="small" />
        <div className={classes.detailWrapper}>
          <div className={classes.titleWrapper}>
            <Typography variant="h6" component="h6" style={{ fontWeight: 'bold' }}>{date ? formattedDate : ''}</Typography>
            <CircularProgress
              className={clsx({ [classes.progressInvisible]: !isLoading })}
            />
          </div>
          {paystub && (
            <div className={classes.paystubWrapper}>
              <section className={classes.paymentsSection}>
                {Object.keys(paystub.payment_types) && (Object.keys(paystub.payment_types).map(paymentTypes))}
              </section>
              <section className={classes.totalSection}>
                <Typography variant="h6" component="h6">Total Paid</Typography>
                <Typography variant="h6" component="h6" className={classes.amountText}>{`$${paystub.total.toFixed(2)}`}</Typography>
              </section>
            </div>
          )}
        </div>

      </div>
    )
  }
  return (
    <>
      {detail()}
    </>

  )
}
